var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "container-wrapper" },
        [
          _c("div", { staticClass: "page-wrapper" }, [
            _vm._m(1),
            _c(
              "div",
              { staticClass: "work-mode-wrap" },
              _vm._l(_vm.workMode, function (item, j) {
                return _c(
                  "div",
                  {
                    key: j,
                    staticClass: "work-mode-box",
                    on: {
                      click: function ($event) {
                        return _vm.handleSelectTeam(item.name)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "work-mode-icon" }, [
                      _c("img", {
                        attrs: { src: "" + _vm.imgLink + item.icon },
                      }),
                    ]),
                    _c("div", { staticClass: "workmode-details" }, [
                      _c("div", { staticClass: "work-mode-name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("div", { staticClass: "work-mode-descrip" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                      _c("div", { staticClass: "action-div" }, [
                        _c("div", { staticClass: "action-text" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                item.name == "Distributed"
                                  ? "Get a Team Instantly"
                                  : "Speak With Us"
                              ) +
                              "\n              "
                          ),
                        ]),
                        _c("div", [
                          _c("img", {
                            attrs: { src: "" + _vm.imgLink + item.actionIocn },
                          }),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm.showCalendly
            ? _c(
                "CalendlyModal",
                _vm._b(
                  {
                    attrs: { client: true },
                    on: { closeModal: _vm.closeCalendly },
                  },
                  "CalendlyModal",
                  { loadCalendly: _vm.loadCalendly, user: _vm.currentUser },
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-container" }, [
      _c("div", { staticClass: "go-back-div" }, [
        _c("img", { attrs: { src: "/img/onboarding/back-icon.svg" } }),
        _c("span", [_vm._v("Go Back")]),
      ]),
      _c("div", { staticClass: "div_cancel" }, [
        _c("img", { attrs: { src: "/img/onboarding/cancel.svg" } }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "content-title" }, [
        _vm._v(
          "\n          How would you prefer to work with your Product Team? Please select\n          one.\n        "
        ),
      ]),
      _c("div", { staticClass: "work-mode-msg" }, [
        _vm._v(
          "\n          This helps us focus on only teams that fit your preferred work mode\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }