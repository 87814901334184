<template>
  <div>
    <Navbar />
    <div class="top-container">
      <div class="go-back-div">
        <img src="/img/onboarding/back-icon.svg" />
        <span>Go Back</span>
      </div>
      <div class="div_cancel">
        <img src="/img/onboarding/cancel.svg" />
      </div>
    </div>
    <div class="container-wrapper">
      <div class="page-wrapper">
        <div class="page-content">
          <div class="content-title">
            How would you prefer to work with your Product Team? Please select
            one.
          </div>
          <div class="work-mode-msg">
            This helps us focus on only teams that fit your preferred work mode
          </div>
        </div>
        <div class="work-mode-wrap">
          <div
            class="work-mode-box"
            @click="handleSelectTeam(item.name)"
            v-for="(item, j) in workMode"
            :key="j"
          >
            <div class="work-mode-icon">
              <img :src="`${imgLink}${item.icon}`" />
            </div>
            <div class="workmode-details">
              <div class="work-mode-name">{{ item.name }}</div>
              <div class="work-mode-descrip">{{ item.text }}</div>
              <div class="action-div">
                <div class="action-text">
                  {{
                    item.name == "Distributed"
                      ? "Get a Team Instantly"
                      : "Speak With Us"
                  }}
                </div>
                <div>
                  <img :src="`${imgLink}${item.actionIocn}`" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalendlyModal
        v-if="showCalendly"
        v-bind:client="true"
        v-on:closeModal="closeCalendly"
        v-bind="{ loadCalendly, user: currentUser }"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/navbar";
import CalendlyModal from "@/components/calendlyModal";
import { mapState } from "vuex";
export default {
  props: {},
  components: {
    Navbar,
    CalendlyModal,
  },
  data: () => ({
    imgLink: "/img/onboarding/",
    workMode: [
      {
        name: "Distributed",
        icon: "distributed-workMode.svg",
        text: "Members of the team work from any location.",
        actionIocn: "arrow-right.svg",
      },
      {
        name: "Custom",
        icon: "custom-workMode.svg",
        text: "Customize your team’s work mode to fit a certain schedule.",
        actionIocn: "calendar-icon.svg",
      },
    ],
    loadCalendly: true,
    showCalendly: false,
  }),
  created() {},
  methods: {
    handleSelectTeam(name) {
      if (name === "Distributed") {
        this.$router.push({
          name: "select_team",
          params: { id: this.$route.params.id },
        });
      } else if (name === "Custom") {
        this.showCalendly = true;
      }
    },
    closeCalendly() {
      this.showCalendly = false;
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.page-content {
  width: 410px;
  margin-top: 127px;
}
.content-title {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
}
.go-back-div,
.div_cancel {
  position: absolute;
  top: 15%;
}
.go-back-div {
  left: 13%;
}
.div_cancel {
  right: 13%;
}
.work-mode-msg {
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.work-mode-wrap {
  margin: 48px 0px 180px;
}
.work-mode-box {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  margin-bottom: 48px;
  cursor: pointer;
}
.work-mode-name {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
}
.work-mode-descrip {
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  width: 269px;
}
.action-div {
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
}
.action-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  text-align: left;
}
.workmode-details {
  margin-left: 16px;
}
.work-mode-box:hover {
  border: 0.5px solid #0781f2;
  box-sizing: border-box;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
</style>